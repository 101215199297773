import React, { Component } from 'react'

export class Register extends Component {
  render() {
    return (
      <div>Register</div>
    )
  }
}

export default Register