import React, { Component } from 'react'

export class AllFilesController extends Component {
  render() {
    return (
      <div>AllFilesController</div>
    )
  }
}

export default AllFilesController